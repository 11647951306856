import React, { useEffect } from 'react';
import { db } from '../../../actions';
import Preloader from '../../Preloader';
import { useDispatch } from 'react-redux';
import {
  APPS_DOMAIN,
  APPS_DOMAIN_OLD,
  AUTH_COOKIE_NAME,
} from '../../../constants/auth';
import Cookies from 'js-cookie';

const SignOut = () => {
  const { updateSession } = db;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      updateSession({
        signOutUser: true,
        newSession: true,
      })
    );
    dispatch(db.createSessionId());

    Cookies.remove(AUTH_COOKIE_NAME, { path: '/', domain: APPS_DOMAIN });
    // TODO remove once domains switched.
    Cookies.remove(AUTH_COOKIE_NAME, { path: '/', domain: APPS_DOMAIN_OLD });
    // eslint-disable-next-line
  }, [])

  return (
    <div className="page-signup">
      <Preloader errorTitle="Error loading Sign Out page" />
    </div>
  );
};

export default SignOut;
