import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { helpers, db } from '../../actions';
import Preloader from '../Preloader';
import * as firebase from '../../firebase/firebase';

import getCustomRoute, {
  getAllIdentificationStatuses,
  phoneVerificationEnabled,
} from '../../actions/getCustomRoute';
import {
  addAccountInfo,
  calculateTrustScore,
  isNewTld,
} from '../../actions/helpers';
import {
  createSessionId,
  fetchAccountInfo,
  setErrorPage,
  updateSession,
} from '../../actions/db';

import useCustomLogin from '@wesalute/lib-auth-client/lib/useCustomLogin';
import { APPS_DOMAIN, APPS_DOMAIN_OLD } from '../../constants/auth';

const Sign = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  /**
   * Verify App Redirect helper.
   */
  const accountData = useSelector((state) => state.accountState.account);
  const configData = useSelector((state) => state.configState.config);
  const configDataStatus = useSelector((state) => state.configState.status);
  const brandUuid = useSelector(
    (state) => state.sessionDBState.data.urlQuery.brand_uuid
  );
  const editMode = useSelector(
    (state) => state.sessionDBState.data.urlQuery.edit
  );
  const hash = useSelector((state) => state.sessionDBState.data.hash);
  const providerToken = useSelector(
    (state) => state.sessionDBState.data.urlQuery.providerToken
  );
  const returnTo = useSelector(
    (state) => state.sessionDBState.data.urlQuery.return_to
  );
  const reverifyMode = useSelector(
    (state) => state.sessionDBState.data.urlQuery.reverify
  );
  const publicComputer = useSelector(
    (state) => state.sessionDBState.data.urlQuery.publicComputer
  );
  const sessionDbId = useSelector((state) => state.sessionDBState.id);
  const signOutUser = useSelector(
    (state) => state.sessionDBState.data.signOutUser
  );
  const staticTexts = useSelector((state) => state.textsState.data);

  /**
   * Helper to get App URL.
   *
   * Needed because configData is not always available.
   *
   * @return {string}
   */
  const getAppUrl = (name) => {
    let url = `https://${name}`;
    const envs = ['dev', 'stage'];
    if (envs.indexOf(process.env.REACT_APP_ENV) !== -1) {
      url += process.env.REACT_APP_ENV === 'stage' ? '-stg' : '-dev';
    }
    // TODO change to new app domain once switched.
    const currentDomain = isNewTld() ? APPS_DOMAIN : APPS_DOMAIN_OLD;
    return url + '.' + currentDomain;
  };
  const signOutHelper = () => {
    // Set computed return URL.
    let computedReturnUrl = getAppUrl('verify') + '/?sessionId=' + sessionDbId;
    if (process.env.REACT_APP_ENV === 'local') {
      computedReturnUrl = `https://verify-local.${APPS_DOMAIN}:3000/?sessionId=${sessionDbId}`;
    }

    if (
      editMode &&
      (editMode === 'true' || editMode === '1' || editMode === 1) &&
      accountData?.verificationStatus !== 'suspended'
    ) {
      computedReturnUrl += '&edit=true';
    }

    if (
      reverifyMode &&
      (reverifyMode === 'true' || reverifyMode === '1' || reverifyMode === 1) &&
      accountData?.verificationStatus !== 'suspended'
    ) {
      computedReturnUrl += '&reverify=true';
    }

    if (returnTo) {
      computedReturnUrl += '&return_to=' + returnTo;
    }

    if (brandUuid) {
      computedReturnUrl += '&brand_uuid=' + decodeURIComponent(brandUuid);
    }

    const token = providerToken ? providerToken : 'vaplatformdefault';

    // Set redirect URL.
    let redirectUrl = getAppUrl('auth');
    if (process.env.REACT_APP_ENV === 'local') {
      // TODO change to new app domain once switched.
      redirectUrl = `https://auth-local.${APPS_DOMAIN}:3001`;
    }
    if (signOutUser) {
      redirectUrl += '/signout';
    }
    redirectUrl +=
      '/?providerToken=' +
      token +
      '&sessionId=' +
      sessionDbId +
      '&return_to=' +
      encodeURIComponent(computedReturnUrl);

    if (publicComputer && publicComputer !== 'false') {
      redirectUrl += '&publicComputer=' + publicComputer;
    }

    // Add hash to redirect URL.
    if (hash) {
      redirectUrl += hash;
    }

    window.location.href = redirectUrl;
  };

  const [authUser] = useCustomLogin(
    firebase.authHelper,
    signOutHelper,
    publicComputer
  );

  // TODO remove once phone verification
  // will be finally adopted in VerifyApp.
  useEffect(() => {
    // Try to calculate phone number trustScore if phone verification disabled in VerifyApp.
    if (!phoneVerificationEnabled) {
      if (accountData?.phone && accountData?.phoneVerified) {
        const { proveStatus } = getAllIdentificationStatuses(accountData);
        if (proveStatus !== 'pending' || proveStatus !== 'failed') {
          calculateTrustScore().catch((error) => console.error(error));
        }
      }
    }
  }, [accountData]);

  useEffect(async () => {
    if (configDataStatus === 'loaded') {
      // If a user is logged in already, redirect to next step.
      if (authUser) {
        // Populate redux authUser data.
        dispatch(db.onSetAuthUser(authUser));
        dispatch(db.fetchAccountInfo(authUser.uid));
        dispatch(updateSession({ populateSession: true }), createSessionId());
        // Move user to needed page.
        if (accountData) {
          if (!reverifyMode || (reverifyMode && accountData?.flags?.reverify)) {
            // Check HH account data.
            checkHouseholdMembers(accountData);
          }
        }
      } else if (!authUser) {
        dispatch(db.onSetAuthUser(null));
      }
    }

    // eslint-disable-next-line
  }, [authUser, accountData, signOutUser])

  // Set user as re-verified.
  useEffect(() => {
    if (reverifyMode && authUser?.uid) {
      addAccountInfo(
        {
          flags: {
            reverify: true,
          },
        },
        authUser?.uid
      )
        .then(() => {
          dispatch(fetchAccountInfo(authUser?.uid));
        })
        .catch((error) => {
          helpers.handleError(error, dispatch);
        });
    }
  }, [reverifyMode, authUser]);

  const checkHouseholdMembers = (accountData) => {
    if (
      accountData.roles &&
      (accountData.roles.includes('vr_household') ||
        accountData.roles.includes('va_household'))
    ) {
      dispatch(
        setErrorPage(
          staticTexts.HHMembersWidgetAlertTitle,
          staticTexts.HHMembersWidgetAlertDesc,
          'warning',
          false,
          staticTexts.HHMembersWidgetAlertBtn,
          false,
          configData?.accountAppUrl
        )
      );
    } else {
      // Helper to handle user routes.
      getCustomRoute(history);
    }
  };

  return (
    <div className="page-signup">
      <Preloader errorTitle="Error loading Sign In page" />
    </div>
  );
};

export default Sign;
